<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.create')} ${$tc('models.user')}`"
  >
    <building-user-form
      :user="user"
      @submit="handleSubmit"
      @cancel="handleCancel"
    ></building-user-form>
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import BuildingUserForm from '../components/BuildingUserForm';
import { createUser } from '../../users/api';
import { getBuilding, getBuildingRole } from '../api';

export default {
  data() {
    return {
      user: {
        email: '',
        role: null,
        building: null
      },
      loading: false
    };
  },

  components: {
    UiCard,
    BuildingUserForm
  },

  methods: {
    async getBuilding() {
      this.loading = true;
      try {
        this.user.building = await getBuilding(
          this.$router.history.current.params.id
        );
        this.user.role = await getBuildingRole();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    },

    async handleSubmit() {
      this.loading = true;
      try {
        await createUser(this.user);
        this.loading = false;
        this.$router.back();
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.back();
    }
  },

  created() {
    this.getBuilding();
  },

  async mounted() {
    let buildingId = this.$route.params.id;
    let building = await getBuilding(buildingId);
    let buildingName = building.name;
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.buildings", link: "/app/buildings/"},
      {name: buildingName, link: "/app/buildings/" + buildingId},
      {name: "models.users", link: "/app/buildings/" + buildingId + "/users"},
      {name: "actions.edit", link: ""}
    ]);
  },
};
</script>
